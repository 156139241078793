import * as React from "react";
import { IProjectType } from "../Model/ProjectModel";
import CreateNewProjectController from "../Controller/CreateNewProjectController";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { IProjectOptions, projectOptions } from "../Static/ProjectOptions";
import Spinner from "../../Spinner/Spinner";
import Error from "../../Error/Error";
import { Input, Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import { IAccessContext, AccessContext } from "access-factory";
import { ADMIN } from "../../../static/AccessLevels.static";

import "./Less/ProjectsView.css";

import SignoutLogo from "../../../assets/logout.png";

interface IProps {
    showDeleteModal: boolean;
    deleteValue: string;
    projectID: number;
    closeDeleteModal: () => void;
    deleteModalInputChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    deleteButton: () => void;

    loading: boolean;
    error: any;
    projects: IProjectType[];
    selectedProjectOption: IProjectOptions;
    deleteProject: (projectID: number) => void;
    getData: () => void;
}

const projectsView: React.FC<IProps> = (props) => {
    const accessContext: IAccessContext = React.useContext(AccessContext);

    if (props.loading && props.deleteValue !== "DELETE") {
        return <Spinner />;
    }
    if (props.error) {
        return <Error />;
    }

    return (
        <div className="projects-container">
            {props.showDeleteModal ? (
                <div>
                    <Modal isOpen={props.showDeleteModal} className={"modal-content"}>
                        <ModalHeader toggle={props.closeDeleteModal}>Are you sure?</ModalHeader>
                        <Input
                            type="textarea"
                            placeholder="Type in DELETE"
                            rows={1}
                            style={{ maxWidth: "94.6%", minHeight: "25px", maxHeight: "25px" }}
                            onChange={props.deleteModalInputChangeHandler}
                        />
                        <ModalFooter>
                            <Button color="primary" onClick={props.deleteButton}>
                                Delete
                            </Button>{" "}
                            <Button color="secondary" onClick={props.closeDeleteModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            ) : null}
            <div className="projects-top-section">
                <div className="projects-top-section__username">Projects</div>
                <div>
                    <img
                        onClick={() => accessContext.logout()}
                        src={SignoutLogo}
                        className="projects-top-section__sign-out"
                    />
                </div>
            </div>
            <div className="projects-container__project-boxes">
                {accessContext.accessLevel.level === ADMIN.level ? (
                    <CreateNewProjectController getData={props.getData} projects={props.projects} />
                ) : null}
                {props.projects.map((project) => (
                    <div key={project.projectID}>
                        {props.deleteValue === "DELETE" &&
                        props.loading &&
                        project.projectID === props.projectID.toString() ? (
                            <Spinner cssClassName="projects-delete-spinner" />
                        ) : (
                            <div>
                                <NavLink
                                    to={"/project/" + project.projectID}
                                    className="individual-project"
                                    key={project.projectID}
                                >
                                    <div className="individual-project__project-title">
                                        {project.projectTitle}
                                    </div>
                                </NavLink>
                                {accessContext.accessLevel.level === ADMIN.level ? (
                                    <Select
                                        className="individual-project__project-options"
                                        //menuIsOpen={true}
                                        classNamePrefix="select-project-option"
                                        placeholder={projectOptions[0]}
                                        value={projectOptions[0]}
                                        isOptionDisabled={(option) => option.disabled === "yes"}
                                        onChange={() =>
                                            props.deleteProject(parseInt(project.projectID))}
                                        options={projectOptions}
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default projectsView;

import * as React from "react";
import NewGroupPageView from "../View/NewGroupView";
import NewGroupDAO, { IDefaultResponse } from "../DAO/NewGroupDAO";
import { IPageGroups } from "../../Layout/Model/PagesModel";

interface INewProps {
    projectID: number;
    groups: IPageGroups[];
    newGroupCreated: () => void;
}

interface INewState {
    newClicked: boolean;
    name: string;
    loading: boolean;
    error: any;
}

class NewGroupPageController extends React.Component<INewProps, INewState> {
    state = {
        newClicked: false,
        name: "",
        loading: false,
        error: null
    };

    submitNewGroup = () => {
        if (this.state.name) {
            let flag: boolean = false;
            this.props.groups.map((group) => {
                if (group.pageGroupName === this.state.name) {
                    flag = true;
                }
            });
            if (!flag) {
                this.setState({ loading: true }, () => {
                    NewGroupDAO.addNewGroup(this.props.projectID, this.state.name)
                        .then((response: IDefaultResponse) => {
                            this.setState(
                                { loading: false, error: null, newClicked: false, name: "" },
                                () => {
                                    this.props.newGroupCreated();
                                }
                            );
                        })
                        .catch((error: any) => {
                            this.setState({ loading: false, error: error });
                        });
                });
            }
        }
    };

    newButtonHandler = () => {
        this.setState({ newClicked: true });
    };

    closeNewComp = () => {
        this.setState({ newClicked: false });
    };

    nameChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ name: event.currentTarget.value });
    };

    render() {
        return (
            <NewGroupPageView
                error={this.state.error}
                loading={this.state.loading}
                newClicked={this.state.newClicked}
                name={this.state.name}
                nameChangeHandler={this.nameChangeHandler}
                newButtonHandler={this.newButtonHandler}
                closeNewComp={this.closeNewComp}
                submitNewGroup={this.submitNewGroup}
            />
        );
    }
}
export default NewGroupPageController;

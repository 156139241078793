import * as React from "react";
import Select, { ValueType } from "react-select";
import { IAccessContext, AccessContext } from "access-factory";
import { ADMIN } from "../../../static/AccessLevels.static";

import "./Less/Param.css";

import { ITypeOption, typeOptions } from "../Static/TypeOptions";
import { IModeOption, modeOptions } from "../Static/ModeOptions";

interface IProps {
    name: string;
    type: ValueType<ITypeOption>;
    description: string;
    mode: ValueType<IModeOption>;

    deleteParameter: () => void;
    selectTypeChangeHandler: (event: ValueType<ITypeOption>) => void;
    selectModeChangeHandler: (event: ValueType<IModeOption>) => void;
    nameChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    descriptionChangeHandler: (event: React.FormEvent<HTMLTextAreaElement>) => void;
}

const paramView: React.FC<IProps> = (props) => {
    const accessContext: IAccessContext = React.useContext(AccessContext);
    let isDisabled: boolean = false;
    if (accessContext.accessLevel.level !== ADMIN.level) {
        isDisabled = true;
    }

    let castedMode: IModeOption = props.mode as IModeOption;
    let optReqColor: string = "";
    switch (castedMode.value) {
        case "required":
            optReqColor = "get-red-required-color";
            break;
        case "optional":
            optReqColor = "get-grey-optional-color";
            break;
        default:
            break;
    }

    return (
        <div className="param-container">
            <div className="param-container__left-section">
                <input
                    className="left-section__name-input"
                    disabled={isDisabled}
                    type="text"
                    placeholder="Parameter name"
                    value={props.name}
                    onChange={props.nameChangeHandler}
                />
                <Select
                    className="left-section__option-select"
                    isDisabled={isDisabled}
                    classNamePrefix={optReqColor}
                    //menuIsOpen={true}
                    placeholder={props.mode}
                    value={props.mode}
                    onChange={props.selectModeChangeHandler}
                    options={modeOptions}
                />
            </div>
            <div className="param-container__right-section">
                <Select
                    className="right-section__type-select"
                    isDisabled={isDisabled}
                    //menuIsOpen={true}
                    classNamePrefix="type-select"
                    placeholder={props.type}
                    value={props.type}
                    onChange={props.selectTypeChangeHandler}
                    options={typeOptions}
                />
                <textarea
                    className="right-section__describe-input"
                    disabled={isDisabled}
                    placeholder="Briefly describe this parameter"
                    value={props.description}
                    onChange={props.descriptionChangeHandler}
                />
            </div>
            {isDisabled ? null : (
                <button className="button-to-delete" onClick={props.deleteParameter}>
                    x
                </button>
            )}
        </div>
    );
};

export default paramView;

import * as React from "react";
import "./Less/Error.css";

interface IProps {}

const error: React.FC<IProps> = (props) => {
    return <div className="error">Something went wrong</div>;
};

export default error;

import * as React from "react";
import { IResponseCodes, responseCodes } from "../Static/ResponseOptions";
import { IAccessContext, AccessContext } from "access-factory";
import { ADMIN } from "../../../static/AccessLevels.static";

import "./Less/ResponseView.css";

import CopyToClipboard from "react-copy-to-clipboard";

import * as Prism from "prismjs";
import Editor from "react-simple-code-editor";
import "prismjs/themes/prism-okaidia.css";
import copyLogo from "../../../assets/copy.png";

interface IProps {
    selectedResponseCode: IResponseCodes;
    responseExample: string;
    codePane: string;

    codePanChangeHandler: (code: string) => void;
    responseExampleChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    deleteResponse: () => void;
}

const responeView: React.FC<IProps> = (props) => {
    const accessContext: IAccessContext = React.useContext(AccessContext);
    let isDisabled: boolean = false;
    if (accessContext.accessLevel.level !== ADMIN.level) {
        isDisabled = true;
    }

    let copied: boolean = false;
    let castedResponseCode: IResponseCodes = props.selectedResponseCode as IResponseCodes;
    let style: string = "";
    if (parseInt(castedResponseCode.value) >= 200 && parseInt(castedResponseCode.value) < 300) {
        style = "top-section__green-circle";
    } else if (
        (parseInt(castedResponseCode.value) < 200 || parseInt(castedResponseCode.value) >= 300) &&
        parseInt(castedResponseCode.value) < 400
    ) {
        style = "top-section__orange-circle";
    } else if (
        parseInt(castedResponseCode.value) >= 400 &&
        parseInt(castedResponseCode.value) < 600
    ) {
        style = "top-section__red-circle";
    }
    return (
        <div className="response-container">
            <div className="response-container__top-section">
                <div className={style} />
                <div className="top-section__selectedOption">{castedResponseCode.label}</div>
                {isDisabled ? null : (
                    <button
                        className="top-section__button-to-delete"
                        onClick={props.deleteResponse}
                    >
                        x
                    </button>
                )}
            </div>
            <div className="response-container__middle-section">
                <input
                    className="middle-section__responeExample-input"
                    disabled={isDisabled}
                    type="text"
                    placeholder="Briefly describe this Response example"
                    value={props.responseExample}
                    onChange={props.responseExampleChangeHandler}
                />
            </div>
            <div className="response-container__bottom-section">
                <div className="bottom-section__codePane">
                    {
                        <Editor
                            disabled={isDisabled}
                            className="code-editor"
                            value={props.codePane}
                            onValueChange={(code) => props.codePanChangeHandler(code)}
                            highlight={(code) => Prism.highlight(code, Prism.languages.js, "js")}
                            padding={15}
                            tabSize={1}
                            insertSpaces={false}
                            style={{
                                padding: "0px 21px 0px 0px"
                            }}
                        />
                    }
                    <div className="bottom-section__clipboard">
                        <CopyToClipboard
                            text={props.codePane}
                            onCopy={() => {
                                copied = true;
                            }}
                        >
                            <img src={copyLogo} className="copyButton" alt="CopyLogo" />
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default responeView;

export interface IMethodOptions {
    value: string;
    label: string;
}

export const methodOptions: IMethodOptions[] = [
    { value: "get", label: "GET" },
    { value: "post", label: "POST" },
    { value: "put", label: "PUT" },
    { value: "delete", label: "DELETE" },
    { value: "patch", label: "PATCH" },
    { value: "head", label: "HEAD" },
    { value: "options", label: "OPTIONS" }
];

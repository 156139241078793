export interface IProjectOptions {
    value: string;
    label: string;
    disabled: string;
}

export const projectOptions: IProjectOptions[] = [
    { value: "options", label: "...", disabled: "yes" },
    { value: "delete", label: "Delete", disabled: "no" }
];

import ProjectModel from "../Model/ProjectModel";
import { axios } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";

export default abstract class ProjectDAO {
    static getProjects = async (): Promise<ProjectModel> => {
        const request: AxiosPromise = axios.getInstance("basic").get("/api/project/all");
        const response: AxiosResponse<IProjectTypeResponse[]> = await request;
        return new ProjectModel(response.data);
    };

    static createProject = async (name: string): Promise<IDefaultResponse> => {
        const formData: FormData = new FormData();
        formData.append("name", name);
        const request: AxiosPromise = axios.getInstance("basic").post("/api/project", formData);
        const response: AxiosResponse<IDefaultResponse> = await request;
        return response.data;
    };

    static deleteProject = async (id: string | null): Promise<IDefaultResponse> => {
        const request: AxiosPromise = axios.getInstance("basic").delete("/api/project/" + id);
        const response: AxiosResponse<IDefaultResponse> = await request;
        return response.data;
    };
}

export interface IDefaultResponse {
    status: string;
    project: string;
}

export interface IProjectTypeResponse {
    project_id: string;
    name: string;
}
//, {headers: {"X-API-KEY": "admin_token" }}

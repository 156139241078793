import { IDynamicObject } from "xa-generics";

export default class Environment {
    constructor(keys?: IDynamicObject) {
        if (!keys) return;

        // Loop through the incoming environment keys.
        for (const property in this) {
            // Throw error if there are unregistered environment variables.
            if (!(property in keys) || keys[property] === "") {
                throw Error(
                    `DEV ERROR: There are missing environment variables in the returning values! ${property}`
                );
            }

            // If the property exists, set its value to the API response.
            Object.assign(this, { [property]: keys[property] });
        }
    }

    public REST_API_URL: string = "";
}

import PageModel from "../Model/PageModel";
import { axios } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";
import qs from "qs";

export default abstract class PageDAO {
    static getPage = async (pageID: string | null): Promise<PageModel> => {
        const request: AxiosPromise = axios.getInstance("basic").get("/api/page/" + pageID);
        const response: AxiosResponse<IPageResponse> = await request;
        return new PageModel(response.data);
    };

    static patchPage = async (
        pageID: string | null,
        data: PageModel
    ): Promise<IDefaultResponse> => {
        const tmpEndpointArray: IEndpointResponse[] = [];
        for (let i = 0; i < data.methodsCollections.length; i++) {
            const tmpResponseArray: IResponseTypeResponse[] = [];
            const tmpParametersArray: IParameterTypeResponse[] = [];

            const parametersType: {
                [key: string]: string;
            } = {
                body: "Body Parameters",
                path: "Path Parameters",
                header: "Headers",
                query: "Query",
                form: "Form"
            };

            for (let key in data.methodsCollections[i].parameters) {
                for (let j = 0; j < data.methodsCollections[i].parameters[key].length; j++) {
                    const tmpParamsArray: IParameterTypeResponse = {
                        name: data.methodsCollections[i].parameters[key][j].parameterName,
                        type: parametersType[key],
                        data_type:
                            data.methodsCollections[i].parameters[key][j].parameterSelectedType
                                .value,
                        is_required:
                            data.methodsCollections[i].parameters[key][j].parameterSelectedMode
                                .value === "required"
                                ? "1"
                                : "0",
                        description:
                            data.methodsCollections[i].parameters[key][j].parameterDescription
                    };
                    tmpParametersArray.push(tmpParamsArray);
                }
            }

            for (let j = 0; j < data.methodsCollections[i].responses.length; j++) {
                const tmresponse: IResponseTypeResponse = {
                    type: data.methodsCollections[i].responses[j].responseCode.value,
                    description: data.methodsCollections[i].responses[j].responseExample,
                    content: data.methodsCollections[i].responses[j].codePane
                };
                tmpResponseArray.push(tmresponse);
            }

            const tmpEndpoint: IEndpointResponse = {
                endpoint_id: data.methodsCollections[i].endpointID,
                name: data.methodsCollections[i].endpointTitle,
                type: data.methodsCollections[i].selectedMethod.value,
                link: data.methodsCollections[i].apiURL,
                description: data.methodsCollections[i].endpointDescription,
                parameters: tmpParametersArray,
                responses: tmpResponseArray
            };
            tmpEndpointArray.push(tmpEndpoint);
        }
        const bodyData: IPageResponse = {
            page_id: data.pageID.toString(),
            name: data.pageTitle,
            description: data.pageDescription,
            endpoints: tmpEndpointArray
        };
        const request: AxiosPromise = axios
            .getInstance("basic")
            .patch("/api/page/" + pageID, qs.stringify(bodyData));
        const response: AxiosResponse<IDefaultResponse> = await request;
        return response.data;
    };
}

export interface IDefaultResponse {
    status: string;
    project: string;
}

export interface IPageResponse {
    page_id: string;
    name: string;
    description: string;
    endpoints: IEndpointResponse[];
}

interface IEndpointResponse {
    endpoint_id: string;
    name: string;
    type: string; //IMethodOptions,
    link: string;
    description: string;
    parameters: IParameterTypeResponse[];
    responses: IResponseTypeResponse[];
}

interface IParameterTypeResponse {
    name: string;
    type: string; //IParamOption,
    data_type: string; //ITypeOption
    is_required: string; //IModeOption,
    description: string;
}

interface IResponseTypeResponse {
    type: string; //IResponseCodes
    description: string;
    content: string;
}

interface IParameterTypeResponse {
    name: string;
    type: string; //ITypeOption,
    data_type: string;
    is_required: string; //IModeOption,
    description: string;
}

import * as React from "react";
import NewGroupPageView from "../View/NewPageView";
import { ValueType } from "react-select";
import { INewOptions, newOptions } from "../Static/GroupOptions";
import { IPageGroups } from "../../Layout/Model/PagesModel";
import NewPageDao, { IDefaultResponse } from "..//DAO/AddNewPageDao";

interface INewProps {
    groups: IPageGroups[];
    newPageCreated: () => void;
}

interface INewState {
    newClicked: boolean;
    name: string;
    selectedNewOption: INewOptions;
    loading: boolean;
    error: any;
}

class NewGroupPageController extends React.Component<INewProps, INewState> {
    state = {
        newClicked: false,
        name: "",
        selectedNewOption: newOptions[0],
        loading: false,
        error: null
    };

    componentDidMount() {
        this.updateGroupOptions();
        this.setState({ selectedNewOption: newOptions[0] });
    }

    componentDidUpdate(prevProps: INewProps) {
        if (prevProps.groups !== this.props.groups) {
            newOptions.length = 0;
            this.updateGroupOptions();
            this.setState({ selectedNewOption: newOptions[0] });
        }
    }

    updateGroupOptions = () => {
        this.props.groups.map((group) =>
            newOptions.push({
                id: group.pageGroupID.toString(),
                value: group.pageGroupName.toString(),
                label: group.pageGroupName.toString().toUpperCase()
            })
        );
    };

    submitNewPage = () => {
        if (this.state.name) {
            let flag: boolean = false;
            this.props.groups.map((group) => {
                if (this.state.selectedNewOption.id === group.pageGroupID.toString()) {
                    group.pagesArray.map((pageGroup) => {
                        if (pageGroup.pageName === this.state.name) {
                            flag = true;
                        }
                    });
                }
            });
            if (!flag && this.props.groups.length !== 0) {
                this.setState({ loading: true }, () => {
                    NewPageDao.addNewPage(this.state.selectedNewOption.id, this.state.name)
                        .then((response: IDefaultResponse) => {
                            this.setState(
                                { loading: false, error: null, newClicked: false, name: "" },
                                () => {
                                    this.props.newPageCreated();
                                }
                            );
                        })
                        .catch((error: any) => {
                            this.setState({ loading: false, error: error });
                        });
                });
            }
        }
    };

    newButtonHandler = () => {
        this.setState({ newClicked: true });
    };

    closeNewComp = () => {
        this.setState({ newClicked: false });
    };

    nameChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ name: event.currentTarget.value });
    };

    selectNewOptionChangeHandler = (event: ValueType<INewOptions>) => {
        this.setState({ selectedNewOption: event as INewOptions });
    };

    render() {
        return (
            <NewGroupPageView
                loading={this.state.loading}
                error={this.state.error}
                newClicked={this.state.newClicked}
                name={this.state.name}
                selectedNewOption={this.state.selectedNewOption}
                nameChangeHandler={this.nameChangeHandler}
                selectNewOptionChangeHandler={this.selectNewOptionChangeHandler}
                newButonHandler={this.newButtonHandler}
                closeNewComp={this.closeNewComp}
                submitNewPage={this.submitNewPage}
            />
        );
    }
}
export default NewGroupPageController;

import { IBaseAccessData } from "access-factory";

export const DEFAULT: IBaseAccessData = Object.freeze({
    type: "DEFAULT",
    level: 1
});

export const ADMIN: IBaseAccessData = Object.freeze({
    type: "ADMIN",
    level: 2
});

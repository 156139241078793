import { axios } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";

export default abstract class AddNewGroupDAO {
    static addNewGroup = async (
        projectID: number,
        groupName: string
    ): Promise<IDefaultResponse> => {
        const formData: FormData = new FormData();
        formData.append("name", groupName);
        const request: AxiosPromise = axios
            .getInstance("basic")
            .post("/api/group/" + projectID, formData);
        const response: AxiosResponse<IDefaultResponse> = await request;
        return response.data;
    };
}

export interface IDefaultResponse {
    status: string;
    project: string;
}

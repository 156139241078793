import * as React from "react";
import Spinner from "../../Spinner/Spinner";
import Error from "../../Error/Error";
import "./Less/CreateNewProjectView.css";

interface IProps {
    nameDuplicateWarning: boolean;
    emptyNameWarning: boolean;
    loading: boolean;
    error: any;
    editorIsOpen: boolean;
    name: string;
    projectNameHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    newProjectHandler: () => void;
    closeEditor: () => void;
    openEditor: () => void;
}

const createNewProjectView: React.FC<IProps> = (props) => {
    if (props.loading) {
        return <Spinner cssClassName="new-project-spinner" />;
    }
    if (props.error) {
        return <Error />;
    }

    return (
        <div>
            {props.nameDuplicateWarning && props.editorIsOpen ? (
                <p className="project-name-warning">You already have a project named like that!</p>
            ) : null}
            {props.emptyNameWarning && props.editorIsOpen ? (
                <p className="project-name-warning">Naming your project is mandatory!</p>
            ) : null}
            {props.editorIsOpen ? (
                <div className="new-project-card">
                    <div className="card-top-section">
                        <input
                            className="new-project-input"
                            type="text"
                            placeholder="Name your new Project"
                            value={props.name}
                            onChange={props.projectNameHandler}
                        />
                    </div>
                    <div className="card-bottom-section">
                        <button className="cancel-new-project-button" onClick={props.closeEditor}>
                            Back
                        </button>
                        <button className="new-project-button" onClick={props.newProjectHandler}>
                            Create
                        </button>
                    </div>
                </div>
            ) : (
                <div className="create-text-card" onClick={props.openEditor}>
                    <div className="create-text">+ create a new project</div>
                </div>
            )}
        </div>
    );
};
export default createNewProjectView;

import * as React from "react";
import Select, { ValueType } from "react-select";
import { INewOptions, newOptions } from "../Static/GroupOptions";
import plusLogo from "../../../assets/plus.png";
import tickLogo from "../../../assets/tick.png";
import Spinner from "../../Spinner/Spinner";
import Error from "../../Error/Error";

import "./Less/NewPage.css";

interface IProps {
    loading: boolean;
    error: any;
    name: string;
    newClicked: boolean;
    selectedNewOption: INewOptions;
    selectNewOptionChangeHandler: (event: ValueType<INewOptions>) => void;
    nameChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    newButonHandler: () => void;
    closeNewComp: () => void;
    submitNewPage: () => void;
}

const newGroupPageView: React.FC<IProps> = (props) => {
    if (props.loading) {
        return <Spinner cssClassName="adding-elements-to-sidebar" />;
    }
    if (props.error) {
        return <Error />;
    }

    return (
        <div>
            {props.newClicked ? (
                <div className="sidebar-add-new-page-group">
                    <Select
                        className="sidebar-add-new-page-group__new-option-select"
                        //menuIsOpen={true}
                        classNamePrefix="selected-new-option"
                        placeholder="Groups"
                        value={props.selectedNewOption}
                        onChange={props.selectNewOptionChangeHandler}
                        options={newOptions}
                    />
                    <input
                        className="sidebar-add-new-page-group__side-name-input"
                        type="text"
                        placeholder="Page name"
                        value={props.name}
                        onChange={props.nameChangeHandler}
                    />
                    <button
                        className="sidebar-add-new-page-group__side-submit-new-page"
                        onClick={props.submitNewPage}
                    >
                        <img className="submit-logo" src={tickLogo} alt="TickLogo" />
                    </button>
                    <button
                        className="sidebar-add-new-page-group__side-close-new-page"
                        onClick={props.closeNewComp}
                    >
                        X
                    </button>
                </div>
            ) : (
                <div className="side-add-new-page-button" onClick={props.newButonHandler}>
                    <div className="new-text">New</div>
                    <div className="plus-icon">
                        <img src={plusLogo} alt="PlusLogo" className="plus-icon-img" />
                    </div>
                </div>
            )}
        </div>
    );
};
export default newGroupPageView;

import * as React from "react";
import { NavLink } from "react-router-dom";
import BackDrop from "./BackDrop";
import { IProject } from "../Model/PagesModel";
import NewGroupPageController from "../../NewGoupPage/Controller/NewPageController";
import NewGroupController from "../../NewGroup/Controller/NewGroupController";
import { Input, ModalBody, Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import { IAccessContext, AccessContext } from "access-factory";
import { ADMIN } from "../../../static/AccessLevels.static";

import Spinner from "../../Spinner/Spinner";
import Error from "../../Error/Error";

import "./Less/SideBar.css";

import pencilLogo from "../../../assets/pencil.svg";
import searchLogo from "../../../assets/search.png";

interface IProps {
    error: any;
    loading: boolean;
    unSaved: boolean;
    backToPorjects: () => void;
    showDeleteModal: boolean;
    deleteValue: string;
    closeDeleteModal: () => void;
    deleteButton: () => void;
    deleteModalInputChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;

    project: IProject;
    isSideBarOpen: boolean;
    selectedID: string | null;
    openSideBar: () => void;
    closeSideBar: () => void;
    selectPageID: (pageID: string | null) => void;
    deletePageButton: (pageID: number) => void;
    deleteGroupButton: (groupID: number) => void;
    newPageCreated: () => void;
    newGroupCreated: () => void;
}

const sideBar: React.FC<IProps> = (props) => {
    const accessContext: IAccessContext = React.useContext(AccessContext);
    let isDisabled: boolean = false;
    if (accessContext.accessLevel.level !== ADMIN.level) {
        isDisabled = true;
    }

    let visibility: string[] = [ "sidebar", "hide" ];
    let buttonsliding = [ "open-sidebar-button", "button-stays-in-place" ];
    let arrowdirection = [ "arrow", "turn-right" ];
    if (props.isSideBarOpen) {
        visibility = [ "sidebar", "show" ];
        buttonsliding = [ "open-sidebar-button", "button-moves" ];
        arrowdirection = [ "arrow", "turn-left" ];
    }

    return (
        <div>
            {props.showDeleteModal ? (
                <div>
                    <Modal isOpen={props.showDeleteModal} className={"modal-content"}>
                        <ModalHeader toggle={props.closeDeleteModal}>Are you sure?</ModalHeader>
                        <Input
                            type="textarea"
                            placeholder="Type in DELETE"
                            rows={1}
                            style={{ maxWidth: "94.6%", minHeight: "25px", maxHeight: "25px" }}
                            onChange={props.deleteModalInputChangeHandler}
                        />

                        <ModalFooter>
                            <Button color="primary" onClick={props.deleteButton}>
                                Delete
                            </Button>{" "}
                            <Button color="secondary" onClick={props.closeDeleteModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            ) : null}
            <div
                className={buttonsliding.join(" ")}
                onClick={props.isSideBarOpen ? props.closeSideBar : props.openSideBar}
            >
                <i className={arrowdirection.join(" ")} />
            </div>

            <BackDrop isSideBarOpen={props.isSideBarOpen} closeSideBar={props.closeSideBar} />
            <div className={visibility.join(" ")}>
                <div className="sidebar-header-section">
                    <div className="sidebar-header-section__side-logo">
                        <img src={pencilLogo} alt="PencilLogo" />
                    </div>
                    <div className="sidebar-header-section__side-project-title">
                        {props.project.projectName}
                    </div>
                    {/* <div className="sidebar-header-section__search-icon"><img src={searchLogo} alt="SearchLogo" className="search-icon-img" /></div> */}
                </div>
                <div className="sidebar-scrollable-section">
                    {props.error ? <Error /> : null}
                    {props.loading ? (
                        <Spinner cssClassName="sidebar-content-spinner" />
                    ) : (
                        <div className="sidebar-page-section">
                            <div className="sidebar-page-section__side-group-list">
                                {props.project.pageGroups.map((pageGroup) => (
                                    <div key={pageGroup.pageGroupID}>
                                        <div className="side-page-groups">
                                            <div className="side-page-groups__side-page-group-name">
                                                {pageGroup.pageGroupName}
                                            </div>
                                            {isDisabled ? null : (
                                                <button
                                                    className="delete-group-button"
                                                    onClick={() =>
                                                        props.deleteGroupButton(
                                                            pageGroup.pageGroupID
                                                        )}
                                                >
                                                    x
                                                </button>
                                            )}
                                        </div>
                                        <div className="page-title-list">
                                            {pageGroup.pagesArray.map((page) => (
                                                <div
                                                    className="page-title-list__page-title-content"
                                                    key={page.pageID}
                                                >
                                                    <div
                                                        className={
                                                            props.selectedID ===
                                                            page.pageID.toString() ? (
                                                                "page-title-selected"
                                                            ) : (
                                                                "page-title"
                                                            )
                                                        }
                                                        onClick={() =>
                                                            props.selectPageID(
                                                                page.pageID.toString()
                                                            )}
                                                    >
                                                        {page.pageName}
                                                    </div>
                                                    {isDisabled ? null : (
                                                        <button
                                                            className={
                                                                props.selectedID ===
                                                                page.pageID.toString() ? (
                                                                    "delete-page-button-selected"
                                                                ) : (
                                                                    " delete-page-button"
                                                                )
                                                            }
                                                            onClick={() =>
                                                                props.deletePageButton(page.pageID)}
                                                        >
                                                            x
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {!isDisabled && (
                        <div className="sidebar-add-new-section">
                            <NewGroupPageController
                                groups={props.project.pageGroups}
                                newPageCreated={props.newPageCreated}
                            />
                            <NewGroupController
                                groups={props.project.pageGroups}
                                projectID={props.project.projectID}
                                newGroupCreated={props.newGroupCreated}
                            />
                        </div>
                    )}
                    <div className="sidebar-bottom-section">
                        <button
                            className="sidebar-bottom-section__back-to-projects-button"
                            onClick={props.backToPorjects}
                        >
                            Back to projects
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default sideBar;

import * as React from "react";
import { NavLink } from "react-router-dom";

import "./Less/unauthorized.css";

interface IProps {}

const spinner: React.FC<IProps> = (props) => {
    return (
        <div className="unauthorized-container">
            <p className="unauthorized-container__informative-text">
                It seems like your access level is insufficient.
            </p>
            <p className="unauthorized-container__informative-text-2">
                Please click on the button to get back to the login screen!
            </p>
            <NavLink to="/login" className="unauthorized-container__get-back-to-login">
                Click me
            </NavLink>
        </div>
    );
};

export default spinner;

export interface IParamOptions {
    value: string;
    label: string;
    disabled: string;
}

export const paramOptions: IParamOptions[] = [
    { value: "addAParam", label: "+ Add a parameter", disabled: "yes" },
    { value: "path", label: "Path Parameters", disabled: "no" },
    { value: "header", label: "Headers", disabled: "no" },
    { value: "query", label: "Query Parameters", disabled: "no" },
    { value: "form", label: "Form Data Parameters", disabled: "no" },
    { value: "body", label: "Body Parameters", disabled: "no" }
];

import * as React from "react";
import Select, { ValueType } from "react-select";
import { IEndpoint } from "../../Page/Model/PageModel";
import { IMethodOptions, methodOptions } from "../Static/MethodOptions";
import { ITypeOption } from "../../Request/Static/TypeOptions";
import { IModeOption } from "../../Request/Static/ModeOptions";
import { IParamOptions } from "../../Request/Static/ParamOptions";
import { IResponseCodes } from "../../Response/Static/ResponseOptions";
import { IAccessContext, AccessContext } from "access-factory";
import { ADMIN } from "../../../static/AccessLevels.static";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddNewParametersView from "../../Request/View/AddNewParametersView";
import AddNewResponsesView from "../../Response/View/AddNewResponseView";

import "./Less/MethodView.css";
import "react-tabs/style/react-tabs.css";

interface IProps {
    method: IEndpoint;
    deleteMethod: () => void;
    selectMethodChangeHandler: (event: ValueType<IMethodOptions>) => void;
    methodTitleChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    methodApiURLChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    methodDescribeChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;

    //Param Functions
    paramNameChangeHandler: (
        key: string,
        arrayIndex: number,
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    paramDescriptionChangeHandler: (
        key: string,
        arrayIndex: number,
        event: React.FormEvent<HTMLTextAreaElement>
    ) => void;
    paramSelectTypeChangeHandler: (
        key: string,
        arrayIndex: number,
        event: ValueType<ITypeOption>
    ) => void;
    paramSelectModeChangeHandler: (
        key: string,
        arrayIndex: number,
        event: ValueType<IModeOption>
    ) => void;
    addNewParameter: (event: ValueType<IParamOptions>) => void;
    deleteParameter: (key: string, index: number) => void;
    //Response Functions
    addNewResponse: (event: ValueType<IResponseCodes>) => void;
    codePanChangeHandler: (responseIndex: number, code: string) => void;
    responseExampleChangeHandler: (
        responseIndex: number,
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    deleteResponse: (responseIndex: number) => void;
}

const methodView: React.FC<IProps> = (props) => {
    const accessContext: IAccessContext = React.useContext(AccessContext);
    let isDisabled: boolean = false;
    if (accessContext.accessLevel.level !== ADMIN.level) {
        isDisabled = true;
    }

    let backGroundColor: string = "";

    switch (props.method.selectedMethod.value) {
        case "get":
            backGroundColor = "get-blue-background-color";
            break;
        case "post":
            backGroundColor = "get-green-background-color";
            break;
        case "put":
            backGroundColor = "get-orange-background-color";
            break;
        case "delete":
            backGroundColor = "get-red-background-color";
            break;
        case "patch":
            backGroundColor = "get-lightBlue-background-color";
            break;
        case "head":
            backGroundColor = "get-purple-background-color";
            break;
        case "options":
            backGroundColor = "get-yellow-background-color";
            break;
        default:
            break;
    }

    return (
        <div className="method-container" id={`${props.method.endpointID}-endpoint`}>
            <div className="method-container__top-method-section">
                <Select
                    className="top-method-section__method-select"
                    isDisabled={isDisabled} //menuIsOpen={true}
                    classNamePrefix={backGroundColor}
                    placeholder={props.method.selectedMethod}
                    value={props.method.selectedMethod}
                    onChange={props.selectMethodChangeHandler}
                    options={methodOptions}
                />
                <input
                    className="top-method-section__title-input"
                    disabled={isDisabled}
                    type="text"
                    placeholder="Give a title to this method"
                    value={props.method.endpointTitle}
                    onChange={props.methodTitleChangeHandler}
                />
                {isDisabled ? null : (
                    <button
                        className="top-method-section__method-to-delete-button"
                        onClick={props.deleteMethod}
                    >
                        x
                    </button>
                )}
            </div>
            <div className="method-container__middle-section">
                <input
                    className="middle-section__apiURL-input"
                    disabled={isDisabled}
                    type="text"
                    placeholder="https://api.acme.com/v1/cakes/:id"
                    value={props.method.apiURL}
                    onChange={props.methodApiURLChangeHandler}
                />
            </div>
            <div className="method-container__bottom-section">
                <input
                    className="bottom-section__describe-input"
                    disabled={isDisabled}
                    type="text"
                    placeholder="Briefly describe this method"
                    value={props.method.endpointDescription}
                    onChange={props.methodDescribeChangeHandler}
                />
            </div>
            <Tabs className="react-tabs">
                <TabList>
                    <Tab>Request</Tab>
                    <Tab>Response</Tab>
                </TabList>

                <TabPanel>
                    <AddNewParametersView
                        parameterLists={props.method.parameters}
                        paramNameChangeHandler={(key, arrayIndex, event) =>
                            props.paramNameChangeHandler(key, arrayIndex, event)
                        }
                        paramDescriptionChangeHandler={(key, arrayIndex, event) =>
                            props.paramDescriptionChangeHandler(key, arrayIndex, event)
                        }
                        paramSelectTypeChangeHandler={(key, arrayIndex, event) =>
                            props.paramSelectTypeChangeHandler(key, arrayIndex, event)
                        }
                        paramSelectModeChangeHandler={(key, arrayIndex, event) =>
                            props.paramSelectModeChangeHandler(key, arrayIndex, event)
                        }
                        addNewParameter={(event) => props.addNewParameter(event)}
                        deleteParameter={(key, arrayIndex) =>
                            props.deleteParameter(key, arrayIndex)
                        }
                    />
                </TabPanel>
                <TabPanel>
                    <AddNewResponsesView
                        endPointResponseCodes={props.method.endPointResponseCodes}
                        responseArray={props.method.responses}
                        codePanChangeHandler={(responseIndex, code) =>
                            props.codePanChangeHandler(responseIndex, code)
                        }
                        responseExampleChangeHandler={(responseIndex, event) =>
                            props.responseExampleChangeHandler(responseIndex, event)
                        }
                        deleteResponse={(responseIndex) => props.deleteResponse(responseIndex)}
                        addNewResponse={(event) => props.addNewResponse(event)}
                    />
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default methodView;

import PagesModel from "../Model/PagesModel";
import { axios } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";

export default abstract class PagesDAO {
    static getPages = async (id: string): Promise<PagesModel> => {
        const request: AxiosPromise = axios.getInstance("basic").get("/api/project/" + id);
        const response: AxiosResponse<IProjectResponse> = await request;
        return new PagesModel(response.data);
    };

    static deletePage = async (pageID: string): Promise<IDefaultResponse> => {
        const request: AxiosPromise = axios.getInstance("basic").delete("/api/page/" + pageID);
        const response: AxiosResponse<IDefaultResponse> = await request;
        return response.data;
    };

    static deleteGroup = async (groupID: number): Promise<IDefaultResponse> => {
        const request: AxiosPromise = axios.getInstance("basic").delete("/api/group/" + groupID);
        const response: AxiosResponse<IDefaultResponse> = await request;
        return response.data;
    };
}

export interface IDefaultResponse {
    status: string;
    project: string;
}

export interface IProjectResponse {
    project_id: number;
    name: string;
    page_groups: PageGroupsResponse[];
}

export interface PageGroupsResponse {
    page_group_id: number;
    name: string;
    pages: IPagesResponse[];
}

export interface IPagesResponse {
    page_id: number;
    name: string;
}

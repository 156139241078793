import * as React from "react";
import CreatNewProjectView from "../View/CreatNewProjectView";
import ProjectDAO, { IDefaultResponse } from "../DAO/ProjectDao";
import { withRouter } from "react-router-dom";
import { IProjectType } from "../Model/ProjectModel";

interface INewProjectProps {
    history: any;
    location: any;
    match: any;
    staticContext?: any;
    projects: IProjectType[];
    getData: () => void;
}

interface INewProject {
    newProjectName: string;
    editorIsOpen: boolean;
    loading: boolean;
    error: any;
    emptyNameWarning: boolean;
    nameDuplicateWarning: boolean;
}

class NewProjectController extends React.Component<INewProjectProps, INewProject> {
    state = {
        newProjectName: "",
        editorIsOpen: false,
        loading: false,
        error: null,
        emptyNameWarning: false,
        nameDuplicateWarning: false
    };

    projectNameHandler = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ newProjectName: event.currentTarget.value });
    };

    openEditor = () => {
        this.setState({ editorIsOpen: true });
    };

    closeEditor = () => {
        this.setState({
            editorIsOpen: false,
            emptyNameWarning: false,
            nameDuplicateWarning: false
        });
    };

    newProjectHandler = () => {
        if (this.state.newProjectName) {
            let flag: boolean = false;
            this.props.projects.map((project) => {
                if (project.projectTitle === this.state.newProjectName) {
                    this.setState({ nameDuplicateWarning: true, emptyNameWarning: false });
                    flag = true;
                }
            });
            if (!flag) {
                this.setState({ loading: true, emptyNameWarning: false }, () => {
                    ProjectDAO.createProject(this.state.newProjectName)
                        .then((response: IDefaultResponse) => {
                            this.setState(
                                {
                                    loading: false,
                                    error: null,
                                    editorIsOpen: false,
                                    newProjectName: ""
                                },
                                () => {
                                    this.props.getData();
                                }
                            );
                        })
                        .catch((error: any) => {
                            this.setState({ loading: false, error: error });
                        });
                });
            }
        } else {
            this.setState({ emptyNameWarning: true, nameDuplicateWarning: false });
        }
    };

    render() {
        return (
            <CreatNewProjectView
                nameDuplicateWarning={this.state.nameDuplicateWarning}
                emptyNameWarning={this.state.emptyNameWarning}
                loading={this.state.loading}
                error={this.state.error}
                editorIsOpen={this.state.editorIsOpen}
                name={this.state.newProjectName}
                projectNameHandler={this.projectNameHandler}
                openEditor={this.openEditor}
                closeEditor={this.closeEditor}
                newProjectHandler={this.newProjectHandler}
            />
        );
    }
}
export default withRouter(NewProjectController);

import * as React from "react";
import Select, { ValueType } from "react-select";
import ResponsesView from "./ResponsesView";
import { IResponseType } from "../../Page/Model/PageModel";
import { IResponseCodes } from "../Static/ResponseOptions";
import { IAccessContext, AccessContext } from "access-factory";
import { ADMIN } from "../../../static/AccessLevels.static";

import "./Less/AddResponseView.css";

interface IProps {
    endPointResponseCodes: IResponseCodes[];
    responseArray: IResponseType[];
    addNewResponse: (event: ValueType<IResponseCodes>) => void;
    codePanChangeHandler: (responseIndex: number, code: string) => void;
    responseExampleChangeHandler: (
        responseIndex: number,
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    deleteResponse: (responseIndex: number) => void;
}

const addResponseView: React.FC<IProps> = (props) => {
    const accessContext: IAccessContext = React.useContext(AccessContext);

    return (
        <div className="response-field-container">
            <div>
                <ResponsesView
                    responseArray={props.responseArray}
                    codePanChangeHandler={props.codePanChangeHandler}
                    responseExampleChangeHandler={props.responseExampleChangeHandler}
                    deleteResponse={props.deleteResponse}
                />
            </div>
            {accessContext.accessLevel.level !== ADMIN.level ? null : (
                <Select
                    className="select-response"
                    //menuIsOpen={true}
                    classNamePrefix="selected-response-indi"
                    placeholder="+ Add Response Example"
                    value={props.endPointResponseCodes[0]}
                    isOptionDisabled={(option) => option.disabled === "yes"}
                    onChange={props.addNewResponse}
                    options={props.endPointResponseCodes}
                />
            )}
        </div>
    );
};

export default addResponseView;

import { IProjectResponse } from "../DAO/PagesDAO";

export default class PageModel {
    projectID: number = 1;
    projectName: string = "Initial Page";
    pageGroups: IPageGroups[] = [];

    constructor(data?: IProjectResponse) {
        if (data) {
            this.projectID = data.project_id;
            this.projectName = data.name;
            for (let i = 0; i < data.page_groups.length; i++) {
                const tmpages: IPages[] = [];
                for (let j = 0; j < data.page_groups[i].pages.length; j++) {
                    const tmp: IPages = {
                        pageID: data.page_groups[i].pages[j].page_id,
                        pageName: data.page_groups[i].pages[j].name
                    };
                    tmpages.push(tmp);
                }
                const pagegroup: IPageGroups = {
                    pageGroupID: data.page_groups[i].page_group_id,
                    pageGroupName: data.page_groups[i].name,
                    pagesArray: tmpages
                };
                this.pageGroups.push(pagegroup);
            }
        }
    }
}

//interface block
export interface IProject {
    projectID: number;
    projectName: string;
    pageGroups: IPageGroups[];
}

export interface IPageGroups {
    pageGroupID: number;
    pageGroupName: string;
    pagesArray: IPages[];
}

export interface IPages {
    pageID: number;
    pageName: string;
}

import * as React from "react";
import { IEndpoint } from "../Model/PageModel";
import MethodView from "../../Method/View/MethodView";
import { IAccessContext, AccessContext } from "access-factory";
import { ADMIN } from "../../../static/AccessLevels.static";

import { ValueType } from "react-select";
import { IMethodOptions } from "../../Method/Static/MethodOptions";
import { ITypeOption } from "../../Request/Static/TypeOptions";
import { IModeOption } from "../../Request/Static/ModeOptions";
import { IParamOptions } from "../../Request/Static/ParamOptions";
import { IResponseCodes } from "../../Response/Static/ResponseOptions";

import tickLogo from "../../../assets/tick.png";
import Spinner from "../../Spinner/Spinner";
import Error from "../../Error/Error";

//import { IPageForm } from "../Static/PageForm";
//import { TextInput, IInputChange } from "../../InputWrapper/InputWrapper";

import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import "./Less/PageView.css";

interface IProps {
    selectedID: string | null;
    saveSpinner: boolean;
    loading: boolean;
    error: any;
    modal: boolean;
    savePageOnModal: () => void;
    openModal: () => void;
    closeModal: () => void;
    doNotSave: () => void;

    projectTitle: number;
    pageTitle: string;
    pageDescription: string;
    pageMethodsCollections: IEndpoint[];
    pagetitleChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    descriptionChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    addNewMethod: () => void;
    deleteMethod: (item: IEndpoint, index: number) => void;
    savePage: () => void;
    //Method Functions
    selectMethodChangeHandler: (event: ValueType<IMethodOptions>, methodIndex: number) => void;
    methodTitleChangeHandler: (
        event: React.FormEvent<HTMLInputElement>,
        methodIndex: number
    ) => void;
    methodApiURLChangeHandler: (
        event: React.FormEvent<HTMLInputElement>,
        methodIndex: number
    ) => void;
    methodDescribeChangeHandler: (
        event: React.FormEvent<HTMLInputElement>,
        methodIndex: number
    ) => void;
    //Param Functions
    paramNameChangeHandler: (
        methodIndex: number,
        key: string,
        arrayIndex: number,
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    paramDescriptionChangeHandler: (
        methodIndex: number,
        key: string,
        arrayIndex: number,
        event: React.FormEvent<HTMLTextAreaElement>
    ) => void;
    paramSelectTypeChangeHandler: (
        methodIndex: number,
        key: string,
        arrayIndex: number,
        event: ValueType<ITypeOption>
    ) => void;
    paramSelectModeChangeHandler: (
        methodIndex: number,
        key: string,
        arrayIndex: number,
        event: ValueType<IModeOption>
    ) => void;
    addNewParameter: (methodIndex: number, event: ValueType<IParamOptions>) => void;
    deleteParameter: (methodIndex: number, key: string, index: number) => void;
    //Response Functions
    addNewResponse: (methodIndex: number, event: ValueType<IResponseCodes>) => void;
    codePanChangeHandler: (methodIndex: number, responseIndex: number, code: string) => void;
    responseExampleChangeHandler: (
        methodIndex: number,
        responseIndex: number,
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    deleteResponse: (methodIndex: number, responseIndex: number) => void;
}

const pageMethodsView: React.FC<IProps> = (props) => {
    const accessContext: IAccessContext = React.useContext(AccessContext);
    let isDisabled: boolean = false;
    if (accessContext.accessLevel.level !== ADMIN.level) {
        isDisabled = true;
    }

    if (props.selectedID === null) {
        return <div className="add-new-page">Add a new Group and Page in the sidebar</div>;
    }
    if (props.loading && !props.saveSpinner) {
        return <Spinner />;
    }
    if (props.error) {
        return <Error />;
    }

    return (
        <div className="page-container">
            {props.modal ? (
                <div>
                    <Modal
                        isOpen={props.modal}
                        toggle={props.openModal}
                        className={"modal-content"}
                    >
                        <ModalHeader toggle={props.closeModal}>
                            You have unsaved changes
                        </ModalHeader>
                        <ModalFooter>
                            <Button color="primary" onClick={props.savePageOnModal}>
                                Save changes
                            </Button>
                            <Button color="secondary" onClick={props.doNotSave}>
                                Continue without saving
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            ) : null}
            <div className="page-top-section">
                {isDisabled ? null : (
                    <div>
                        {props.loading && props.saveSpinner ? (
                            <Spinner cssClassName="saving-page-spinner" />
                        ) : (
                            <button
                                className="page-top-section__save-button"
                                onClick={props.savePage}
                            >
                                <img className="tick-logo" src={tickLogo} alt="TickLogo" />
                                <span className="save-text">Save</span>
                            </button>
                        )}
                    </div>
                )}

                <input
                    className="page-top-section__title-input"
                    disabled={isDisabled}
                    type="text"
                    placeholder="InitialPage"
                    value={props.pageTitle}
                    onChange={props.pagetitleChangeHandler}
                />
                <input
                    className="page-top-section__page-description"
                    disabled={isDisabled}
                    type="text"
                    placeholder="Page description (optional)"
                    value={props.pageDescription}
                    onChange={props.descriptionChangeHandler}
                />
            </div>
            <div className="page-container__page-middle-section">
                {props.pageMethodsCollections.map((methodCollection, methodIndex) => (
                    <div className="border-between-methods" key={methodCollection.endpointID}>
                        <MethodView
                            method={methodCollection}
                            deleteMethod={() => props.deleteMethod(methodCollection, methodIndex)}
                            selectMethodChangeHandler={(event) =>
                                props.selectMethodChangeHandler(event, methodIndex)}
                            methodTitleChangeHandler={(event) =>
                                props.methodTitleChangeHandler(event, methodIndex)}
                            methodApiURLChangeHandler={(event) =>
                                props.methodApiURLChangeHandler(event, methodIndex)}
                            methodDescribeChangeHandler={(event) =>
                                props.methodDescribeChangeHandler(event, methodIndex)}
                            paramNameChangeHandler={(key, arrayIndex, event) =>
                                props.paramNameChangeHandler(methodIndex, key, arrayIndex, event)}
                            paramDescriptionChangeHandler={(key, arrayIndex, event) =>
                                props.paramDescriptionChangeHandler(
                                    methodIndex,
                                    key,
                                    arrayIndex,
                                    event
                                )}
                            paramSelectTypeChangeHandler={(key, arrayIndex, event) =>
                                props.paramSelectTypeChangeHandler(
                                    methodIndex,
                                    key,
                                    arrayIndex,
                                    event
                                )}
                            paramSelectModeChangeHandler={(key, arrayIndex, event) =>
                                props.paramSelectModeChangeHandler(
                                    methodIndex,
                                    key,
                                    arrayIndex,
                                    event
                                )}
                            addNewParameter={(event) => props.addNewParameter(methodIndex, event)}
                            deleteParameter={(key, arrayIndex) =>
                                props.deleteParameter(methodIndex, key, arrayIndex)}
                            codePanChangeHandler={(responseIndex, code) =>
                                props.codePanChangeHandler(methodIndex, responseIndex, code)}
                            responseExampleChangeHandler={(responseIndex, event) =>
                                props.responseExampleChangeHandler(
                                    methodIndex,
                                    responseIndex,
                                    event
                                )}
                            deleteResponse={(responseIndex) =>
                                props.deleteResponse(methodIndex, responseIndex)}
                            addNewResponse={(event) => props.addNewResponse(methodIndex, event)}
                        />
                    </div>
                ))}
                <div className={"page-container__page-bottom-section"}>
                    {isDisabled ? null : (
                        <button className="method-to-add-button" onClick={props.addNewMethod}>
                            +
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default pageMethodsView;

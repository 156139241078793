import * as React from "react";
import PageView from "../View/PageView";
import { cloneDeep, uniqueId } from "lodash";
import PageDAO from "../DAO/PageDAO";
import PageModel, { IEndpoint } from "../Model/PageModel";

import { ValueType } from "react-select";
import { IMethodOptions, methodOptions } from "../../Method/Static/MethodOptions";
import { ITypeOption, typeOptions } from "../../Request/Static/TypeOptions";
import { IModeOption, modeOptions } from "../../Request/Static/ModeOptions";
import { IParamOptions } from "../../Request/Static/ParamOptions";
import { IResponseCodes, responseCodes } from "../../Response/Static/ResponseOptions";

interface IPageProps {
    backToProjects: boolean;
    history: any;
    modal: boolean;
    unSaved: boolean;
    selectedID: string | null;
    setPageID: () => void;
    setUnSaved: (value: boolean) => void;
    setModal: (value: boolean) => void;
    setBackToProjectsFalse: () => void;
}

export interface IPageState {
    data: PageModel;
    loading: boolean;
    error: any;
    saveSpinner: boolean;
}

class PageController extends React.Component<IPageProps, IPageState> {
    state = {
        loading: true,
        error: null,
        saveSpinner: false,
        data: new PageModel()
    };

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps: IPageProps, prevState: IPageState) {
        if (prevProps.selectedID !== this.props.selectedID) {
            if (!this.props.unSaved) {
                this.setState({ loading: true, saveSpinner: false }, () => this.getData());
            }
        }
    }

    patchData = () => {
        PageDAO.patchPage(this.props.selectedID, this.state.data)
            .then((response) => {
                this.setState({ loading: true, error: null }, () => {
                    this.getData();
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error });
            });
    };

    getData = () => {
        if (this.props.selectedID !== null) {
            PageDAO.getPage(this.props.selectedID)
                .then((response) => {
                    this.setState({ data: response, loading: false, error: null });
                })
                .catch((error) => {
                    this.setState({ loading: false, error });
                });
        }
    };
    openModal = () => {
        this.props.setModal(true);
    };
    closeModal = () => {
        this.props.setModal(false);
    };

    savePage = () => {
        this.setState({ saveSpinner: true });
        this.props.setModal(false);
        this.props.setUnSaved(false);
        this.patchData();
    };

    savePageOnModal = () => {
        this.patchData();
        this.props.setModal(false);
        this.props.setUnSaved(false);
        this.props.setPageID();
        if (this.props.backToProjects) {
            this.props.setBackToProjectsFalse();
            this.props.history.push("/");
        }
    };

    doNotSave = () => {
        this.props.setPageID();
        this.props.setModal(false);
        this.props.setUnSaved(false);
        if (this.props.backToProjects) {
            this.props.setBackToProjectsFalse();
            this.props.history.push("/");
        }
    };

    //Page Functions
    pagetitleChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.pageTitle = event.currentTarget.value;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    descriptionChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.pageDescription = event.currentTarget.value;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    addNewMethod = () => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections.push({
            endpointID: uniqueId("test"),
            endpointTitle: "",
            selectedMethod: methodOptions[0],
            apiURL: "",
            endpointDescription: "",
            parameters: {
                path: [
                    {
                        parameterName: "",
                        parameterSelectedType: typeOptions[0],
                        parameterDescription: "",
                        parameterSelectedMode: modeOptions[0]
                    }
                ],
                header: [],
                query: [],
                form: [],
                body: []
            },
            responses: [
                {
                    responseCode: { value: "200", label: "200: OK", disabled: "no" },
                    responseExample: "",
                    codePane: ""
                }
            ],
            endPointResponseCodes: responseCodes
        });
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    deleteMethod = (item: IEndpoint, index: number) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections.splice(index, 1);
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    //Method Functions
    selectMethodChangeHandler = (event: ValueType<IMethodOptions>, methodIndex: number) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].selectedMethod = event as IMethodOptions;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    methodTitleChangeHandler = (event: React.FormEvent<HTMLInputElement>, methodIndex: number) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].endpointTitle = event.currentTarget.value;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    methodApiURLChangeHandler = (event: React.FormEvent<HTMLInputElement>, methodIndex: number) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].apiURL = event.currentTarget.value;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    methodDescribeChangeHandler = (
        event: React.FormEvent<HTMLInputElement>,
        methodIndex: number
    ) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].endpointDescription =
            event.currentTarget.value;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    //Request functions
    paramNameChangeHandler = (
        methodIndex: number,
        key: string,
        paramIndex: number,
        event: React.FormEvent<HTMLInputElement>
    ) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].parameters[key][paramIndex].parameterName =
            event.currentTarget.value;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    paramDescriptionChangeHandler = (
        methodIndex: number,
        key: string,
        paramIndex: number,
        event: React.FormEvent<HTMLTextAreaElement>
    ) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].parameters[key][
            paramIndex
        ].parameterDescription =
            event.currentTarget.value;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    paramSelectTypeChangeHandler = (
        methodIndex: number,
        key: string,
        paramIndex: number,
        event: ValueType<ITypeOption>
    ) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].parameters[key][
            paramIndex
        ].parameterSelectedType = event as ITypeOption;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    paramSelectModeChangeHandler = (
        methodIndex: number,
        key: string,
        paramIndex: number,
        event: ValueType<IModeOption>
    ) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].parameters[key][
            paramIndex
        ].parameterSelectedMode = event as IModeOption;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    addNewParameter = (methodIndex: number, event: ValueType<IParamOptions>) => {
        let cloneState = cloneDeep(this.state);
        const selectedParam: IParamOptions = event as IParamOptions;
        cloneState.data.methodsCollections[methodIndex].parameters[selectedParam.value].push({
            parameterName: "",
            parameterSelectedType: typeOptions[0],
            parameterDescription: "",
            parameterSelectedMode: modeOptions[0]
        });
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    deleteParameter = (methodIndex: number, key: string, paramIndex: number) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].parameters[key].splice(paramIndex, 1);
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    //Response Functions/
    responseExampleChangeHandler = (
        methodIndex: number,
        responseIndex: number,
        event: React.FormEvent<HTMLInputElement>
    ) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].responses[responseIndex].responseExample =
            event.currentTarget.value;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    codePanChangeHandler = (methodIndex: number, responseIndex: number, code: string) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].responses[responseIndex].codePane = code;
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    deleteResponse = (methodIndex: number, responseIndex: number) => {
        let cloneState = cloneDeep(this.state);
        cloneState.data.methodsCollections[methodIndex].endPointResponseCodes.push({
            value: cloneState.data.methodsCollections[methodIndex].responses[
                responseIndex
            ].responseCode.value.toString(),
            label: cloneState.data.methodsCollections[methodIndex].responses[
                responseIndex
            ].responseCode.label.toString(),
            disabled: "no"
        });
        cloneState.data.methodsCollections[methodIndex].endPointResponseCodes.sort(
            (a: any, b: any) => a.value - b.value
        );
        cloneState.data.methodsCollections[methodIndex].responses.splice(responseIndex, 1);
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    addNewResponse = (methodIndex: number, selectedOption: ValueType<IResponseCodes>) => {
        let cloneState = cloneDeep(this.state);
        let option: IResponseCodes = selectedOption as IResponseCodes;
        let flag = true;
        this.state.data.methodsCollections[methodIndex].responses.map((key) => {
            if (key.responseCode.value === option.value) {
                flag = false;
            }
        });
        if (flag) {
            cloneState.data.methodsCollections[methodIndex].responses.push({
                responseCode: option,
                responseExample: "",
                codePane: ""
            });
            cloneState.data.methodsCollections[
                methodIndex
            ].endPointResponseCodes.map((key, index) => {
                if (key.value === option.value) {
                    cloneState.data.methodsCollections[methodIndex].endPointResponseCodes.splice(
                        index,
                        1
                    );
                }
            });
        }
        this.props.unSaved ? null : this.props.setUnSaved(true);
        this.setState(cloneState);
    };

    render() {
        return (
            <div>
                <PageView
                    selectedID={this.props.selectedID}
                    saveSpinner={this.state.saveSpinner}
                    loading={this.state.loading}
                    error={this.state.error}
                    modal={this.props.modal}
                    savePageOnModal={this.savePageOnModal}
                    savePage={this.savePage}
                    doNotSave={this.doNotSave}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    projectTitle={this.state.data.pageID}
                    pageTitle={this.state.data.pageTitle}
                    pageDescription={this.state.data.pageDescription}
                    pageMethodsCollections={this.state.data.methodsCollections}
                    pagetitleChangeHandler={this.pagetitleChangeHandler}
                    descriptionChangeHandler={this.descriptionChangeHandler}
                    addNewMethod={this.addNewMethod}
                    deleteMethod={this.deleteMethod}
                    selectMethodChangeHandler={this.selectMethodChangeHandler}
                    methodTitleChangeHandler={this.methodTitleChangeHandler}
                    methodApiURLChangeHandler={this.methodApiURLChangeHandler}
                    methodDescribeChangeHandler={this.methodDescribeChangeHandler}
                    paramNameChangeHandler={this.paramNameChangeHandler}
                    paramDescriptionChangeHandler={this.paramDescriptionChangeHandler}
                    paramSelectTypeChangeHandler={this.paramSelectTypeChangeHandler}
                    paramSelectModeChangeHandler={this.paramSelectModeChangeHandler}
                    addNewParameter={this.addNewParameter}
                    deleteParameter={this.deleteParameter}
                    codePanChangeHandler={this.codePanChangeHandler}
                    responseExampleChangeHandler={this.responseExampleChangeHandler}
                    deleteResponse={this.deleteResponse}
                    addNewResponse={this.addNewResponse}
                />
            </div>
        );
    }
}
export default PageController;

import { IProjectTypeResponse } from "../DAO/ProjectDao";

export default class ProjectsModel {
    projects: IProjectType[] = [];

    constructor(data?: IProjectTypeResponse[]) {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                let array: IProjectType = {
                    projectID: data[i].project_id,
                    projectTitle: data[i].name
                };
                this.projects.push(array);
            }
        }
    }
}

export interface IProjects {
    projects: IProjectType[];
}

export interface IProjectType {
    projectID: string;
    projectTitle: string;
}

import * as React from "react";
import { AccessContext } from "access-factory";
import LoginView from "../View/LoginView";
import { withRouter, Redirect } from "react-router-dom";
import { IError, IHistory } from "xa-generics";

interface IProps {
    location: any;
    match: any;
    staticContext?: any;
    history: IHistory<any>;
}

interface IPagesState {
    email: string;
    password: string;
    emptyfields: boolean;
    error: IError;
}

class LoginController extends React.Component<IProps, IPagesState> {
    state: IPagesState = {
        email: "",
        password: "",
        emptyfields: false,
        error: null
    };

    emailChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ email: event.currentTarget.value });
    };

    passwordChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ password: event.currentTarget.value });
    };

    onSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        if (this.state.email === "" || this.state.password === "") {
            this.setState({ emptyfields: true });
        } else {
            this.context.login({
                email: this.state.email,
                password: this.state.password
            });
        }
    };

    render() {
        if (this.context.accessLevel.level > 0) {
            return (
                <Redirect
                    to={{
                        pathname: "/"
                    }}
                />
            );
        }

        return (
            <LoginView
                email={this.state.email}
                password={this.state.password}
                emptyfields={this.state.emptyfields}
                error={this.context.error}
                onSubmit={this.onSubmit}
                emailChangeHandler={this.emailChangeHandler}
                passwordChangeHandler={this.passwordChangeHandler}
            />
        );
    }
}
LoginController.contextType = AccessContext;
export default withRouter(LoginController);

import * as React from "react";
import ResponseView from "./ResponseView";
import { IResponseType } from "../../Page/Model/PageModel";
import "./Less/ResponsesView.css";

interface IProps {
    responseArray: IResponseType[];
    codePanChangeHandler: (index: number, code: string) => void;
    responseExampleChangeHandler: (index: number, event: React.FormEvent<HTMLInputElement>) => void;
    deleteResponse: (index: number) => void;
}

const responsesView: React.FC<IProps> = (props) => {
    props.responseArray.sort((a: any, b: any) => a.responseCode.value - b.responseCode.value);

    return (
        <div className="responses-block">
            <div className="responses-block__array-of-params">
                {props.responseArray.map((item, index) => (
                    <ResponseView
                        key={index}
                        responseExample={item.responseExample}
                        selectedResponseCode={item.responseCode}
                        codePane={item.codePane}
                        deleteResponse={() => props.deleteResponse(index)}
                        codePanChangeHandler={(code) => props.codePanChangeHandler(index, code)}
                        responseExampleChangeHandler={(event) =>
                            props.responseExampleChangeHandler(index, event)}
                    />
                ))}
            </div>
        </div>
    );
};

export default responsesView;

export interface ITypeOption {
    value: string;
    label: string;
}

export const typeOptions: ITypeOption[] = [
    { value: "string", label: "String" },
    { value: "number", label: "Number" },
    { value: "integer", label: "Integer" },
    { value: "boolean", label: "Boolean" },
    { value: "array", label: "Array" },
    { value: "object", label: "Object" }
];

import * as React from "react";
import tickLogo from "../../../assets/tick.png";
import folderLogo from "../../../assets/folder.png";
import Spinner from "../../Spinner/Spinner";
import Error from "../../Error/Error";

import "./Less/NewGroupView.css";

interface IProps {
    error: any;
    loading: boolean;
    name: string;
    newClicked: boolean;
    nameChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    newButtonHandler: () => void;
    closeNewComp: () => void;
    submitNewGroup: () => void;
}

const newGroupPageView: React.FC<IProps> = (props) => {
    if (props.loading) {
        return <Spinner cssClassName="adding-elements-to-sidebar" />;
    }
    if (props.error) {
        return <Error />;
    }

    return (
        <div>
            {props.newClicked ? (
                <div className="sidebar-add-new-group">
                    <input
                        className="sidebar-add-new-group__side-group-name-input"
                        type="text"
                        placeholder="Group name"
                        value={props.name}
                        onChange={props.nameChangeHandler}
                    />
                    <button
                        className="sidebar-add-new-group__side-submit-new-group"
                        onClick={props.submitNewGroup}
                    >
                        <img className="submit-logo" src={tickLogo} alt="TickLogo" />
                    </button>
                    <button
                        className="sidebar-add-new-group__side-close-new-group"
                        onClick={props.closeNewComp}
                    >
                        X
                    </button>
                </div>
            ) : (
                <div className="side-folders-button" onClick={props.newButtonHandler}>
                    <div className="folder-text">Folder</div>
                    <div className="folder-icon">
                        <img src={folderLogo} alt="FolderLogo" className="folder-icon-img" />
                    </div>
                </div>
            )}
        </div>
    );
};
export default newGroupPageView;

import * as React from "react";
import "./Less/Spinner.css";
import { stringify } from "querystring";

interface IProps {
    cssClassName?: string;
}

const spinner: React.FC<IProps> = (props) => {
    let cssName: string = "loader";
    if (props.cssClassName) {
        cssName = props.cssClassName;
    }
    return <div className={cssName} />;
};

export default spinner;

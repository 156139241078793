import * as React from "react";
import "./Less/BackDrop.css";

interface IProps {
    isSideBarOpen: boolean;
    closeSideBar: () => void;
}

const backdrop: React.FC<IProps> = (props) => {
    return props.isSideBarOpen ? <div className={"backdrop"} onClick={props.closeSideBar} /> : null;
};

export default backdrop;

export interface IResponseCodes {
    value: string;
    label: string;
    disabled: string;
}

export const responseCodes: IResponseCodes[] = [
    { value: "addResponseExample", label: "+ Add Response Example", disabled: "yes" },
    { value: "100", label: "100: Continue", disabled: "no" },
    { value: "101", label: "101: Switching Protocols", disabled: "no" },
    { value: "102", label: "102: Processing", disabled: "no" },
    { value: "103", label: "103: Early Hints", disabled: "no" },
    { value: "201", label: "201: Created", disabled: "no" },
    { value: "202", label: "202: Accepted", disabled: "no" },
    { value: "203", label: "203: Non-Authoritative Information", disabled: "no" },
    { value: "204", label: "204: No Content", disabled: "no" },
    { value: "205", label: "205: Reset Content", disabled: "no" },
    { value: "206", label: "206: Partial Content", disabled: "no" },
    { value: "207", label: "207: Multi Status", disabled: "no" },
    { value: "208", label: "208: Already Reported", disabled: "no" },
    { value: "226", label: "226: IM Used", disabled: "no" },
    { value: "300", label: "300: Multiple Choices", disabled: "no" },
    { value: "301", label: "301: Moved Permanently", disabled: "no" },
    { value: "302", label: "302: Not Found", disabled: "no" },
    { value: "303", label: "303: See Other", disabled: "no" },
    { value: "304", label: "304: Not Modified", disabled: "no" },
    { value: "305", label: "305: Use Proxy", disabled: "no" },
    { value: "306", label: "306: Switch Proxy", disabled: "no" },
    { value: "307", label: "307: Temporary Redirect", disabled: "no" },
    { value: "308", label: "308: Permanent Redirect", disabled: "no" },
    { value: "400", label: "400: Bad Request", disabled: "no" },
    { value: "401", label: "401: Unauthorized", disabled: "no" },
    { value: "402", label: "402: Payment Required", disabled: "no" },
    { value: "403", label: "403: Forbidden", disabled: "no" },
    { value: "404", label: "404: Not Found", disabled: "no" },
    { value: "405", label: "405: Method Not Allowed", disabled: "no" },
    { value: "406", label: "406: Not Acceptable", disabled: "no" },
    { value: "407", label: "407: Proxy Authentication Required", disabled: "no" },
    { value: "408", label: "408: Request Time-out", disabled: "no" },
    { value: "409", label: "409: Conflict", disabled: "no" },
    { value: "410", label: "410: Gone", disabled: "no" },
    { value: "411", label: "411: Length Required", disabled: "no" },
    { value: "412", label: "412: Precondition Failed", disabled: "no" },
    { value: "413", label: "413: Request Entity Too Large", disabled: "no" },
    { value: "414", label: "414: Request-URI Too Large", disabled: "no" },
    { value: "415", label: "415: Unsupported Media Type", disabled: "no" },
    { value: "416", label: "416: Requested Range not Satisfiabl", disabled: "no" },
    { value: "417", label: "417: Expectation Failed", disabled: "no" },
    { value: "418", label: "418: I'm teapot", disabled: "no" },
    { value: "421", label: "421: Misdirected Request", disabled: "no" },
    { value: "422", label: "422: Unprocessable", disabled: "no" },
    { value: "423", label: "423: Locked", disabled: "no" },
    { value: "424", label: "424: Failed Dependency", disabled: "no" },
    { value: "426", label: "426: Upgrade Required", disabled: "no" },
    { value: "428", label: "428: Precondition Required", disabled: "no" },
    { value: "429", label: "429: Too Many Requests", disabled: "no" },
    { value: "431", label: "431: Request Header Fields Too Large", disabled: "no" },
    { value: "451", label: "451: Unavailable For Legal Reasons", disabled: "no" },
    { value: "500", label: "500: Internal Server Error", disabled: "no" },
    { value: "501", label: "501: Not Implemented", disabled: "no" },
    { value: "502", label: "502: Bad Gateway", disabled: "no" },
    { value: "503", label: "503: Service Unavailable", disabled: "no" },
    { value: "504", label: "504: Gateway Time-out", disabled: "no" },
    { value: "505", label: "505: HTTP Version not Supported", disabled: "no" },
    { value: "506", label: "506: Variant Also Negotiates", disabled: "no" },
    { value: "507", label: "507: Insufficient Storage", disabled: "no" },
    { value: "508", label: "508: Loop Detected", disabled: "no" },
    { value: "510", label: "510: Not Extended", disabled: "no" },
    { value: "511", label: "511: Network Authentication Required", disabled: "no" }
];

import { IPageResponse } from "../DAO/PageDAO";
import { IMethodOptions, methodOptions } from "../../Method/Static/MethodOptions";
import { ITypeOption, typeOptions } from "../../Request/Static/TypeOptions";
import { IModeOption, modeOptions } from "../../Request/Static/ModeOptions";
import { IResponseCodes, responseCodes } from "../../Response/Static/ResponseOptions";

export default class PageModel {
    pageID: number = 0;
    pageTitle: string = "Initial Page";
    pageDescription: string = "Page descripption (optional)";

    methodsCollections: IEndpoint[] = [];

    constructor(data?: IPageResponse) {
        if (data) {
            this.pageID = parseInt(data.page_id);
            this.pageTitle = data.name;
            this.pageDescription = data.description;

            for (let i = 0; i < data.endpoints.length; i++) {
                const responsesArray: IResponseType[] = [];
                const parametersArray: IParametersList = {
                    path: [],
                    header: [],
                    query: [],
                    form: [],
                    body: []
                };
                for (let j = 0; j < data.endpoints[i].parameters.length; j++) {
                    switch (data.endpoints[i].parameters[j].type) {
                        case "Path Parameters": {
                            const tmp: IParametersType = {
                                parameterName: data.endpoints[i].parameters[j].name,
                                parameterSelectedType: selectedValueType(
                                    data.endpoints[i].parameters[j].data_type
                                ),
                                parameterDescription: data.endpoints[i].parameters[j].description,
                                parameterSelectedMode: selectedModeType(
                                    data.endpoints[i].parameters[j].is_required
                                )
                            };
                            parametersArray.path.push(tmp);
                            break;
                        }
                        case "Headers": {
                            const tmp: IParametersType = {
                                parameterName: data.endpoints[i].parameters[j].name,
                                parameterSelectedType: selectedValueType(
                                    data.endpoints[i].parameters[j].data_type
                                ),
                                parameterDescription: data.endpoints[i].parameters[j].description,
                                parameterSelectedMode: selectedModeType(
                                    data.endpoints[i].parameters[j].is_required
                                )
                            };
                            parametersArray.header.push(tmp);
                            break;
                        }
                        case "Query": {
                            const tmp: IParametersType = {
                                parameterName: data.endpoints[i].parameters[j].name,
                                parameterSelectedType: selectedValueType(
                                    data.endpoints[i].parameters[j].data_type
                                ),
                                parameterDescription: data.endpoints[i].parameters[j].description,
                                parameterSelectedMode: selectedModeType(
                                    data.endpoints[i].parameters[j].is_required
                                )
                            };
                            parametersArray.query.push(tmp);
                            break;
                        }
                        case "Form": {
                            const tmp: IParametersType = {
                                parameterName: data.endpoints[i].parameters[j].name,
                                parameterSelectedType: selectedValueType(
                                    data.endpoints[i].parameters[j].data_type
                                ),
                                parameterDescription: data.endpoints[i].parameters[j].description,
                                parameterSelectedMode: selectedModeType(
                                    data.endpoints[i].parameters[j].is_required
                                )
                            };
                            parametersArray.form.push(tmp);
                            break;
                        }
                        case "Body Parameters": {
                            const tmp: IParametersType = {
                                parameterName: data.endpoints[i].parameters[j].name,
                                parameterSelectedType: selectedValueType(
                                    data.endpoints[i].parameters[j].data_type
                                ),
                                parameterDescription: data.endpoints[i].parameters[j].description,
                                parameterSelectedMode: selectedModeType(
                                    data.endpoints[i].parameters[j].is_required
                                )
                            };
                            parametersArray.body.push(tmp);
                            break;
                        }
                    }
                }

                for (let j = 0; j < data.endpoints[i].responses.length; j++) {
                    const tmresponse: IResponseType = {
                        responseCode: selectedResponseType(data.endpoints[i].responses[j].type),
                        responseExample: data.endpoints[i].responses[j].description,
                        codePane: data.endpoints[i].responses[j].content
                    };
                    responsesArray.push(tmresponse);
                }

                const tmp: IResponseCodes[] = [...responseCodes];
                for (let k = 0; k < data.endpoints[i].responses.length; k++) {
                    for (let j = 0; j < tmp.length; j++) {
                        if (tmp[j].value === data.endpoints[i].responses[k].type) {
                            tmp.splice(j, 1);
                        }
                    }
                }

                const endpoint: IEndpoint = {
                    endpointID: data.endpoints[i].endpoint_id,
                    endpointTitle: data.endpoints[i].name,
                    selectedMethod: selectedMethodType(data.endpoints[i].type),
                    apiURL: data.endpoints[i].link,
                    endpointDescription: data.endpoints[i].description,
                    parameters: parametersArray,
                    responses: responsesArray,
                    endPointResponseCodes: tmp
                };
                this.methodsCollections.push(endpoint);
            }
        }
    }
}

//interface block
export interface IEndpoint {
    endpointID: string;
    endpointTitle: string;
    selectedMethod: IMethodOptions;
    apiURL: string;
    endpointDescription: string;
    parameters: IParametersList;
    responses: IResponseType[];
    endPointResponseCodes: IResponseCodes[];
}

interface IParameterListProto {
    [key: string]: any;
}

export interface IParametersList extends IParameterListProto {
    path: IParametersType[];
    header: IParametersType[];
    query: IParametersType[];
    form: IParametersType[];
    body: IParametersType[];
}

export interface IParametersType {
    parameterName: string;
    parameterSelectedType: ITypeOption;
    parameterDescription: string;
    parameterSelectedMode: IModeOption;
}

export interface IResponseType {
    responseCode: IResponseCodes; //IResponseCode
    responseExample: string;
    codePane: string;
}

//function block

function selectedMethodType(type: string) {
    let selectedType: IMethodOptions = methodOptions[1];
    methodOptions.map((key, index) => {
        if (key.value === type.toLowerCase()) {
            selectedType = methodOptions[index];
        }
    });
    return selectedType;
}

function selectedValueType(type: string) {
    let selectedType: ITypeOption = typeOptions[0];
    typeOptions.map((key, index) => {
        if (key.value === type) {
            selectedType = typeOptions[index];
        }
    });
    return selectedType;
}

function selectedModeType(type: string) {
    let selectedType: IModeOption = modeOptions[0];
    switch (type) {
        case "0":
            break;
        case "1":
            selectedType = modeOptions[1];
            break;
    }
    return selectedType;
}

function selectedResponseType(type: string) {
    let selectedType: IResponseCodes = { value: "200", label: "200: OK", disabled: "no" };
    responseCodes.map((key, index) => {
        if (key.value === type) {
            selectedType = responseCodes[index];
        }
    });
    return selectedType;
}

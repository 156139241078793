import { axios } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";

export default abstract class AddNewPageDAO {
    static addNewPage = async (groupID: string, name: string): Promise<IDefaultResponse> => {
        const formData: FormData = new FormData();
        formData.append("name", name);
        const request: AxiosPromise = axios
            .getInstance("basic")
            .post("/api/page/" + groupID, formData);
        const response: AxiosResponse<IDefaultResponse> = await request;
        return response.data;
    };
}

export interface IDefaultResponse {
    status: string;
    project: string;
}

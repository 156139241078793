import * as React from "react";
import ProjectsView from "../View/ProjectsView";
import ProjectsModel from "../Model/ProjectModel";
import ProjectDAO from "../DAO/ProjectDao";
import { withRouter } from "react-router-dom";
import { IProjectOptions, projectOptions } from "../Static/ProjectOptions";

interface IProjectsProps {
    history: any;
    location: any;
    match: any;
    staticContext?: any;
}

interface IProjects {
    data: ProjectsModel;
    newProject: boolean;
    loading: boolean;
    error: any;
    selectedProjectOption: IProjectOptions;
    projectID: number;
    showModal: boolean;
    deleteValue: string;
}

class ProjectsController extends React.Component<IProjectsProps, IProjects> {
    state = {
        data: new ProjectsModel(),
        newProject: false,
        loading: true,
        error: null,
        selectedProjectOption: projectOptions[0],
        projectID: 0,
        showModal: false,
        deleteValue: ""
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        ProjectDAO.getProjects()
            .then((response) => {
                this.setState({ data: response, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false, error });
            });
    };

    closeDeleteModal = () => {
        this.setState({ showModal: false, deleteValue: "" });
    };

    deleteModalInputChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ deleteValue: event.currentTarget.value });
    };

    deleteButton = () => {
        if (this.state.deleteValue === "DELETE") {
            ProjectDAO.deleteProject(this.state.projectID.toString())
                .then((response) => {
                    this.setState({ loading: true, error: null, deleteValue: "DELETE" }, () => {
                        this.getData();
                    });
                })
                .catch((error) => {
                    this.setState({ loading: false, error });
                });
            this.closeDeleteModal();
        }
    };

    deleteProject = (projectID: number) => {
        this.setState({ showModal: true, projectID: projectID });
    };

    render() {
        return (
            <ProjectsView
                showDeleteModal={this.state.showModal}
                closeDeleteModal={this.closeDeleteModal}
                deleteModalInputChangeHandler={this.deleteModalInputChangeHandler}
                deleteValue={this.state.deleteValue}
                projectID={this.state.projectID}
                deleteButton={this.deleteButton}
                loading={this.state.loading}
                error={this.state.error}
                projects={this.state.data.projects}
                selectedProjectOption={this.state.selectedProjectOption}
                deleteProject={this.deleteProject}
                getData={this.getData}
            />
        );
    }
}
export default withRouter(ProjectsController);

import * as React from "react";
import Select, { ValueType } from "react-select";
import ParamsView from "./ParamsView";
import { IParametersList } from "../../Page/Model/PageModel";
import { IParamOptions, paramOptions } from "../Static/ParamOptions";
import { ITypeOption } from "../Static/TypeOptions";
import { IModeOption } from "../Static/ModeOptions";
import { IAccessContext, AccessContext } from "access-factory";
import { ADMIN } from "../../../static/AccessLevels.static";

import "./Less/AddNewParams.css";

interface IProps {
    parameterLists: IParametersList;
    paramNameChangeHandler: (
        key: string,
        index: number,
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    paramDescriptionChangeHandler: (
        key: string,
        index: number,
        event: React.FormEvent<HTMLTextAreaElement>
    ) => void;
    paramSelectTypeChangeHandler: (
        key: string,
        index: number,
        event: ValueType<ITypeOption>
    ) => void;
    paramSelectModeChangeHandler: (
        key: string,
        index: number,
        event: ValueType<IModeOption>
    ) => void;
    addNewParameter: (event: ValueType<IParamOptions>) => void;
    deleteParameter: (key: string, index: number) => void;
}

const addNewParameters: React.FC<IProps> = (props) => {
    const accessContext: IAccessContext = React.useContext(AccessContext);

    let buttonToAddRequests = (
        <div className="request-field-container__request-selection">
            <Select
                className="request-selection"
                //menuIsOpen={true}
                classNamePrefix="selected-request"
                placeholder="+ Add a parameter"
                value={paramOptions[0]}
                isOptionDisabled={(option) => option.disabled === "yes"}
                onChange={props.addNewParameter}
                options={paramOptions}
            />
        </div>
    );

    const parameters = Object.keys(props.parameterLists).map(
        (key) =>
            props.parameterLists[key].length !== 0 &&
            props.parameterLists[key].length !== undefined ? (
                <ParamsView
                    key={key}
                    array={props.parameterLists[key]}
                    arrayTitle={key}
                    paramNameChangeHandler={(index, event) =>
                        props.paramNameChangeHandler(key, index, event)}
                    paramDescriptionChangeHandler={(index, event) =>
                        props.paramDescriptionChangeHandler(key, index, event)}
                    paramSelectTypeChangeHandler={(index, event) =>
                        props.paramSelectTypeChangeHandler(key, index, event)}
                    paramSelectModeChangeHandler={(index, event) =>
                        props.paramSelectModeChangeHandler(key, index, event)}
                    deleteParameter={(index) => props.deleteParameter(key, index)}
                />
            ) : null
    );

    return (
        <div className="request-field-container">
            <div className="__parameters-section">{parameters}</div>
            {accessContext.accessLevel.level !== ADMIN.level ? null : buttonToAddRequests}
        </div>
    );
};

export default addNewParameters;

import { AccessContext } from "access-factory";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import { ErrorHandler } from "xa-error-handler";
import "./Less/LoginView.css";

interface IProps {
    email: string;
    password: string;
    emptyfields: boolean;
    error: number;
    emailChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    passwordChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    onSubmit: (event: React.FormEvent) => void;
}

const loginView: React.FC<IProps> = (props) => {
    const { accessLevel, loginLoading, loginError } = React.useContext(AccessContext);

    if (accessLevel.level > 0) {
        return <Redirect to="/" />;
    }

    return (
        <div className="login-container">
            <div className="login-container__login-modal">
                <div className="login-modal__sign-up">Sign in!</div>
                <form onSubmit={props.onSubmit} className="login-form">
                    <input
                        className="login-modal__email-input input-format"
                        type="text"
                        placeholder="Email Address"
                        onChange={props.emailChangeHandler}
                    />
                    <input
                        className="login-modal__password-input input-format"
                        type="password"
                        placeholder="Password"
                        onChange={props.passwordChangeHandler}
                    />
                    <div className="login-modal__loading">{loginLoading && <Spinner />}</div>
                    {props.emptyfields ? (
                        <p className="login-modal__fields-to-fill-warning">
                            Please fill all the required fields!
                        </p>
                    ) : null}
                    <ErrorHandler error={props.error || loginError} />
                    <button className="login-modal__login-buttons" type="submit">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default loginView;
